// libs
import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  // Search User
  searchUserRequest: ['params'],
  searchUserSuccess: ['data'],
  searchUserFailed: ['error'],

  changePlanForUserRequest: ['params', 'callback'],
  changePlanForUserSuccess: ['data'],
  changePlanForUserFailed: ['error'],

  // Get User
  getUserRequest: ['params'],
  getUserSuccess: ['data'],
  getUserFailed: ['error'],

  // get credits for User
  getCreditsRequest: ['params'],
  getCreditsSuccess: ['data'],
  getCreditsFailed: ['error'],

  // GET credit balance adjustments
  getCreditBalanceAdjustmentsRequest: ['params'],
  getCreditBalanceAdjustmentsSuccess: ['data'],
  getCreditBalanceAdjustmentsFailed: ['error'],

  // change the password of a user
  changeUserPasswordRequest: ['params', 'callback'],
  changeUserPasswordSuccess: ['data'],
  changeUserPasswordFailed: ['error'],

  // get list check ins by userID
  getCheckInsRequest: ['params'],
  getCheckInsSuccess: ['data'],
  getCheckInsFailed: ['error'],

  // get list reservations by userID
  getReservationsRequest: ['data'],
  getReservationsSuccess: ['data'],
  getReservationsFailed: ['error'],

  // get list subscriptions by userID
  getSubscriptionsRequest: ['data'],
  getSubscriptionsSuccess: ['data'],
  getSubscriptionsFailed: ['error'],

  // get card details
  getCardDetailsRequest: ['userID'],
  getCardDetailsSuccess: ['data'],
  getCardDetailsFailed: ['error'],

  getCardsForUserRequest: ['userID'],
  getCardsForUserSuccess: ['data'],
  getCardsForUserFailed: ['error'],

  getActivePlanForUserRequest: ['userID'],
  getActivePlanForUserSuccess: ['data'],
  getActivePlanForUserFailed: ['error'],

  getPlansForUserRequest: ['userID', 'callback'],
  getPlansForUserSuccess: ['data'],
  getPlansForUserFailed: ['error'],

  // update profile for user
  updateProfileRequest: ['params', 'callback'],
  updateProfileSuccess: ['data'],
  updateProfileFailed: ['error'],

  // update credits for user
  updateAdjustCreditsRequest: ['params', 'callback'],
  updateAdjustCreditsSuccess: ['data'],
  updateAdjustCreditsFailed: ['error'],

  // reset password
  resetPasswordRequest: ['params'],
  resetPasswordSuccess: ['data'],
  resetPasswordFailed: ['error'],

  // cancel user action
  cancelUserRequest: ['params', 'callback'],
  cancelUserSuccess: ['data'],
  cancelUserFailed: ['error'],

  // backdate cancel user action
  backdateCancelUserRequest: ['params', 'callback'],
  backdateCancelUserSuccess: ['data'],
  backdateCancelUserFailed: ['error'],

  removeCancellationRequest: ['params', 'callback'],
  removeCancellationSuccess: ['data'],
  removeCancellationFailed: ['error'],

  // get active address details
  getActiveAddressDetailsRequest: ['userID'],
  getActiveAddressDetailsSuccess: ['data'],
  getActiveAddressDetailsFailed: ['error'],

  // update active address for user
  updateActiveUserAddressRequest: ['params', 'callback'],
  updateActiveUserAddressSuccess: ['data'],
  updateActiveUserAddressFailed: ['error'],

  // create card for user
  createCardForUserRequest: ['params', 'callback'],
  createCardForUserSuccess: ['data'],
  createCardForUserFailed: ['error'],

  // activate user
  activateUserRequest: ['params', 'callback'],
  activateUserSuccess: ['data'],
  activateUserFailed: ['error'],

  // retry payment for user
  retryPaymentForUserRequest: ['params', 'callback'],
  retryPaymentForUserSuccess: ['data'],
  retryPaymentForUserFailed: ['error'],

  // get referrals list
  getFriendInvitationsRequest: ['userID'],
  getFriendInvitationsSuccess: ['data'],
  getFriendInvitationsFailed: ['error'],

  // get referral conversions list
  getFriendConversionsRequest: ['userID'],
  getFriendConversionsSuccess: ['data'],
  getFriendConversionsFailed: ['error'],

  // startSubscriptionEarlyForFreeTrialUser
  startSubscriptionEarlyForFreeTrialUserRequest: ['params', 'callback'],
  startSubscriptionEarlyForFreeTrialUserSuccess: ['data'],
  startSubscriptionEarlyForFreeTrialUserFailed: ['error'],
});
